// Imports
import ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ from "../../../../node_modules/html-loader/dist/runtime/getUrl.js";
var ___HTML_LOADER_IMPORT_0___ = new URL("../../../Content/img/dark-theme.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("../../../Content/img/classic-theme.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("../../../Content/img/blue-theme.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_3___ = new URL("../../../Content/img/light-theme.svg", import.meta.url);
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var ___HTML_LOADER_REPLACEMENT_3___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_3___);
var code = "<div class=\"modal-dialog modal-lg\" role=\"document\" id=\"chooseThemeModal\">\r\n    <div class=\"modal-content\">\r\n        <div class=\"modal-body\">\r\n            <button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-label=\"Close\">\r\n                <i class=\"icon-cancel\"></i>\r\n            </button>\r\n            <h3>Set UI Theme:</h3>\r\n            \r\n            <div class=\"theme-previews\">\r\n                <div class=\"theme theme-dark\" data-bind=\"click: _.partial(useTheme, 'dark'), css: { 'active': currentTheme() === 'dark' }\">\r\n                    <img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" class=\"img-responsive\">\r\n                    <h2 class=\"theme-title\">Dark</h2>\r\n                </div>\r\n                <div class=\"theme theme-classic\" data-bind=\"click: _.partial(useTheme, 'classic'), css: { 'active': currentTheme() === 'classic' }\">\r\n                    <img src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\" class=\"img-responsive\">\r\n                    <h2 class=\"theme-title\">Classic</h2>\r\n                </div>\r\n                <div class=\"theme theme-blue\" data-bind=\"click: _.partial(useTheme, 'blue'), css: { 'active': currentTheme() === 'blue' }\">\r\n                    <img src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\" class=\"img-responsive\">\r\n                    <h2 class=\"theme-title\">Blue</h2>\r\n                </div>\r\n                <div class=\"theme theme-light\" data-bind=\"click: _.partial(useTheme, 'light'), css: { 'active': currentTheme() === 'light' }\">\r\n                    <img src=\"" + ___HTML_LOADER_REPLACEMENT_3___ + "\" class=\"img-responsive\">\r\n                    <h2 class=\"theme-title\">Light</h2>\r\n                </div>\r\n            </div>\r\n        </div>\r\n    </div>\r\n</div>\r\n";
// Exports
export default code;